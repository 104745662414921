import {ArrowDownIcon, CheckCircleIcon} from '@heroicons/react/20/solid'
import ScrollButton from "./ScrollButton";
import { ArrowUpIcon } from '@heroicons/react/20/solid'
import {motion} from "framer-motion";
import React from "react";

const UpButton= ({ targetRef }) => {
    const handleButtonClick = () => {
        if (targetRef && targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
            <div className="flex items-center justify-center h-48">
            <div className="absolute z-10">
                <motion.button
                    whileHover={{scale: 1.1, boxShadow: '0 10px 10px rgba(0, 0, 0, 0.2)', transition: {duration: 0.2}}}
                    whileTap={{scale: 0.6}}
                    onClick={handleButtonClick}
                    className="bg-pink-700 hover:bg-pink-900 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
                >
                    <ArrowUpIcon className={"h-5 w-5"}/>
                    Zrugg zum Ahfang
                </motion.button>
            </div>
            </div>
        </>
    )
}

export default UpButton;