import logo from './logo.svg';
import './App.css';
import React, {Fragment, useRef, useState} from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import milica from './assets/milica_1.PNG'
import Welcome from "./modules/Welcome";
import Spacer from "./modules/Spacer";
import ScrollButton from "./modules/ScrollButton";
import { useScroll, motion, useSpring, useTransform } from "framer-motion"
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Stats from "./modules/Stats";
import Feed from "./modules/Feed";
import Abo from "./modules/Abo";
import Newsletter from "./modules/Newsletter";
import UpButton from "./modules/UpButton";
import ScrollAnimation from "./modules/ScrollAnimation";
import Testimonial from "./modules/Testimonial";
import Calendar from "./modules/Calendar";

const navigation = [
  { name: 'Einleitung', href: '#', icon: HomeIcon, current: true },
  { name: 'Ich', href: '#', icon: UsersIcon, current: false },
  { name: 'Projects', href: '#', icon: FolderIcon, current: false },
  { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
  { name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false },
  { name: 'Reports', href: '#', icon: ChartPieIcon, current: false },
]
const teams = [
  { id: 1, name: 'Du und di quasi "stabile Arsch"', href: '#', initial: 'L', current: false },
  { id: 2, name: 'Du und die cutee Lächle', href: '#', initial: 'O', current: false },
  { id: 3, name: 'Du und di herzige Bizeps', href: '#', initial: 'L', current: false },
]

const UserProfile = () => (
    <div>
      <h2>Your Profile Page</h2>
      {/* Add your profile page content here */}
    </div>
);

const SignOut = () => (
    <div>
      <h2>Sign Out Page</h2>
      {/* Add sign-out page content here */}
    </div>
);

const userNavigation = [
  { name: 'Your profile', href: '/profile' },
  { name: 'Sign out', href: '/signout' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const firstDivRef = useRef();
  const secondDivRef = useRef();
  const thirdDivRef = useRef();
  const fourthDivRef = useRef();
  const fifthDivRef = useRef();
  const sixthDivRef = useRef();
  const seventhDivRef = useRef();
  const {scrollYProgress} = useScroll();
  const scaleX = useSpring(scrollYProgress, {stiffness: 400, damping: 90});
  const background = useTransform(scrollYProgress, [0, 1], ["#ff008c", "#7700ff"]);

  return (
  <>
    {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
        <div>

          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
              <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </Transition.Child>

              <div className="fixed inset-0 flex">
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                      <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                      <div className="flex h-16 shrink-0 items-center">
                        <img
                            className="h-8 w-auto"
                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                            alt="Your Company"
                        />
                      </div>
                      <Router>
                        <nav className="flex flex-1 flex-col">
                          <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                              <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                      <Link
                                          to={item.href} // Replace item.href with the actual route
                                          className={classNames(
                                              item.current
                                                  ? 'bg-gray-50 text-indigo-600'
                                                  : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                          )}
                                      >
                                        <item.icon
                                            className={classNames(
                                                item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                'h-6 w-6 shrink-0'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    </li>
                                ))}
                              </ul>
                            </li>
                            <li>
                              <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                              <ul role="list" className="-mx-2 mt-2 space-y-1">
                                {teams.map((team) => (
                                    <li key={team.name}>
                                      <a
                                          href={team.href}
                                          className={classNames(
                                              team.current
                                                  ? 'bg-gray-50 text-indigo-600'
                                                  : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                          )}
                                      >
                                    <span
                                        className={classNames(
                                            team.current
                                                ? 'text-indigo-600 border-indigo-600'
                                                : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                        )}
                                    >
                                      {team.initial}
                                    </span>
                                        <span className="truncate">{team.name}</span>
                                      </a>
                                    </li>
                                ))}
                              </ul>
                            </li>
                            <li className="mt-auto">
                              <a
                                  href="#"
                                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                              >
                                <Cog6ToothIcon
                                    className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                                    aria-hidden="true"
                                />
                                Settings
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </Router>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
              <div className="flex h-16 shrink-0 items-center">
                <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt="Your Company"
                />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item) => (
                          <li key={item.name}>
                            <a
                                href={item.href}
                                className={classNames(
                                    item.current
                                        ? 'bg-gray-50 text-indigo-600'
                                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                            >
                              <item.icon
                                  className={classNames(
                                      item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                      'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                              />
                              {item.name}
                            </a>
                          </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      {teams.map((team) => (
                          <li key={team.name}>
                            <a
                                href={team.href}
                                className={classNames(
                                    team.current
                                        ? 'bg-gray-50 text-indigo-600'
                                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                            >
                          <span
                              className={classNames(
                                  team.current
                                      ? 'text-indigo-600 border-indigo-600'
                                      : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                  'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                              )}
                          >
                            {team.initial}
                          </span>
                              <span className="truncate">{team.name}</span>
                            </a>
                          </li>
                      ))}
                    </ul>
                  </li>
                  <li className="mt-auto">
                    <a
                        href="#"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                    >
                      <Cog6ToothIcon
                          className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                          aria-hidden="true"
                      />
                      Settings
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className="lg:pl-72">
            <div
                className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
              <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                      onClick={() => setSidebarOpen(true)}>

                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
              </button>

              {/* Separator */}
              <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true"/>
              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <form className="relative flex flex-1" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <MagnifyingGlassIcon
                      className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                      aria-hidden="true"
                  />
                  <input
                      id="search-field"
                      className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm bg-white focus-within:bg-white"
                      placeholder="Search..."
                      type="search"
                      name="search"
                  />
                </form>
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true"/>
                  </button>

                  {/* Separator */}
                  <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true"/>
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative">
                    <Menu.Button className="-m-1.5 flex items-center p-1.5">
                      <span className="sr-only">Open user menu</span>
                      <img
                          className="h-8 w-8 rounded-full bg-gray-50"
                          src={milica}
                          alt="Milica"
                      />
                      <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        Milica
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </span>
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                          className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({active}) => (
                                  <a
                                      href={item.href}
                                      className={classNames(
                                          active ? 'bg-gray-50' : '',
                                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                                      )}
                                  >
                                    {item.name}
                                  </a>
                              )}
                            </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>


            <main className="text-center">
              <motion.div
                  style={{
                    //scaleX: scrollYProgress,
                    scaleX,
                    background,
                    transformOrigin: "left",
                    position: "sticky",
                    width: "100%",
                    height: "0.5rem",
                    zIndex: "101",
                    top: "64px",
                  }}/>
              <div className="py-10 px-4 sm:px-6 lg:px-8">
                <motion.div ref={firstDivRef}>
                  <Welcome title={"Dobrodošli"}
                           desc={"Eigentlich hets alles nur mit e Frog ahgfange, wo ich dir ha welle stelle..."}/>
                </motion.div>

                <ScrollButton targetRef={secondDivRef}/>
                <Spacer/>
                <div ref={secondDivRef}>
                  <Welcome title={"Aber..."}
                           desc={"Bin busy und muess epis Neues lerne - also wieso nid neui Webtechnologie mit de Frog kombiniere? ;)"}/>
                </div>
                <ScrollButton targetRef={thirdDivRef}/>
                <Spacer/>
                <div ref={thirdDivRef}>
                  <Welcome title={"Übrigens..."}
                           desc={"Wenn mit mir ufem Töff fahre wilsch, muesch dir eigeni Usrüstig kaufe, ich kauf dir safe nid"}/>
                </div>
                <ScrollButton targetRef={fourthDivRef}/>
                <Spacer/>
                <div ref={fourthDivRef}>
                  <Welcome title={"Die Site..."}
                           desc={"...isch en Art zämmefassig und Joke glichzitig, ich muess das schriebe will du jo seisch: du muesch dich no an mi Humor gwöhnee"}/>
                </div>
                <ScrollButton targetRef={fifthDivRef}/>
                <Spacer/>
                <div ref={fifthDivRef}>
                  <Welcome title={"Epis no zu  mir..."}
                           desc={"...mini Statistik"}/>
                </div>
                <Stats/>
                <ScrollButton targetRef={sixthDivRef}/>
                <Spacer/>
                <div ref={sixthDivRef} className={"px-6 sm:px-6 lg:px-8 -mt-12"}>
                  <Welcome title={"Bisherige Verlauf"}
                           desc={""}/>
                  <Feed/>
                </div>
                <Spacer/>
                <Abo/>
                <Spacer/>
              </div>
              <Newsletter/>
              <ScrollButton targetRef={seventhDivRef}/>
              <Spacer/>
              <div ref={seventhDivRef} className={"px-6 sm:px-6 lg:px-8 -mt-12"}>
                <Welcome title={"Ich ha dir scho alles im Kalender ihtreiht"}
                         desc={""}/>
                <Calendar/>
              </div>
              <Spacer/>
              <Testimonial/>
              <UpButton targetRef={firstDivRef}/>
            </main>
          </div>
        </div>
  </>
  )
}

