import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon, PowerIcon,TrophyIcon, PhoneIcon, SparklesIcon, HandThumbUpIcon} from '@heroicons/react/24/outline'

const stats = [
    { id: 1, name: 'Gym Days', stat: '2', icon: PowerIcon, change: '10%', changeType: 'increase' },
    { id: 2, name: 'Mit Fraue telefoniert', stat: '2', icon: PhoneIcon, change: '50%', changeType: 'increase' },
    { id: 3, name: 'Interessanti Fraue kenneglernt', stat: '1', icon: UsersIcon, change: '100%', changeType: 'increase' },
    { id: 4, name: 'Kaffi wo mir abekejt sind', stat: '3', icon: HandThumbUpIcon, change: '20%', changeType: 'decrease' },
    { id: 5, name: 'Lüt gwürgt in MMA', stat: '1', icon: SparklesIcon, change: '20%', changeType: 'decrease' },
    { id: 6, name: 'Dates plant', stat: '1', icon: TrophyIcon, change: '100%', changeType: 'increase' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Stats() {
    return (

        <div className={"-mt-20"}>

            <h3 className="text-base font-semibold leading-6 text-gray-900">Last week</h3>

            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">

                {stats.map((item) => (

                    <div
                        key={item.id}
                        className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                    >
                        <div
                            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                        </div>
                        <dt>
                            <div className="absolute rounded-md bg-indigo-500 p-3">
                                <item.icon className="h-6 w-6 text-white" aria-hidden="true"/>
                            </div>

                            <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
                        </dt>
                        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                            <p
                                className={classNames(
                                    item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}
                            >
                                {item.changeType === 'increase' ? (
                                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                                                 aria-hidden="true"/>
                                ) : (
                                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                                                   aria-hidden="true"/>
                                )}

                                <span
                                    className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                                {item.change}
                            </p>
                            <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm">
                                    <a href="" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        View all<span className="sr-only"> {item.name} stats</span>
                                    </a>
                                </div>
                            </div>
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    )
}
